import * as angular from 'angular';

const orderObjectBy = () => function (items, field, reverse, isNumeric) {

  const filtered = [];
  var isNumeric = !!isNumeric;
  angular.forEach(items, (item) => {
    filtered.push(item);
  });
  filtered.sort((a, b) => {
    if (isNumeric) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else if (a[field] && b[field]) {
      if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
      if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
    } else {
      if (!a[field]) {
        return 1;
      }
      if (!b[field]) {
        return -1;
      }
    }
    return 0;
  });
  if (reverse) filtered.reverse();
  return filtered;
}

export default angular.module('orderObjectBy', []).filter('orderObjectBy', orderObjectBy).name;
