import * as angular from 'angular';
import OrganizationService from '../services/OrganizationService';
import UserService from '../services/UserService';
import { jsPDF } from 'jspdf';

const ReportsOrganizationController = function ($rootScope, $state, $scope, $stateParams, $translate, UserService, currentUser, 
  user, organization, diversMap, OrganizationService) {

  const vm = this;
  vm.user = user;
  vm.currentUser = currentUser;
  vm.diverId = $stateParams.uid;
  vm.date = '';
  vm.diveType = $state.params.diveType || 'water';
  const { organizationId } = $stateParams;
  vm.years = [];
  vm.organizationId = organizationId;
  vm.dives = [];
  vm.lang = $rootScope.lang;
  vm.translate = $translate.instant;
  vm.convertTime = function (time, format) {
    return dayjs(time).format(format || 'DD.MM.YYYY');
  };
  vm.exposures = [];
  vm.months = ['MONTH_SHORT', ...Array(12).keys().map((v) => (++v) + '')].map((m) => {
    return { name: vm.translate(m), value: m };
  });

  vm.shifts = organization.settings.shifts ? 
    [{ name:'ALL_WORK_SHIFTS', value: 'ALL_WORK_SHIFTS' }, 
      ...(Object.values(organization.settings.shifts).map((s) => { return { name: s.name, value: s.name } }))] : 
    ['ALL_WORK_SHIFTS', ...Array(4).keys().map((v) => (++v) + '')].map((m) => {
      return { name: m, value: m };
    });

  vm.allDiveParameters = OrganizationService.getAllDiveOrganizationParameters(vm.sites);
  vm.diveParameters = [...vm.allDiveParameters];

  vm.allRescueDiveParameters = OrganizationService.getAllRescueDiveOrganizationParameters();
  vm.rescueDiveParameters = [vm.allRescueDiveParameters[0], vm.allRescueDiveParameters[1], vm.allRescueDiveParameters[2],
    vm.allRescueDiveParameters[3], vm.allRescueDiveParameters[4], vm.allRescueDiveParameters[5],];

  vm.allExposureParameters = OrganizationService.getAllExposureOrganizationParameters();
  vm.exposureParameters = [...vm.allExposureParameters];
  // [vm.allExposureParameters[0], vm.allExposureParameters[1], vm.allExposureParameters[3], vm.allExposureParameters[4],];

  const startDate = dayjs('2015-01-01');
  const endDate = dayjs().endOf('year');
  let yearDate = endDate;

  if (endDate.isBefore(startDate)) {
    throw 'End date must be greated than start date.';
  }

  vm.years.push({ name: 'YEAR', value: 'ALL'});
  while (yearDate.isAfter(startDate)) {
    vm.years.push({ name: yearDate.format('YYYY'), value: yearDate.format('YYYY') });
    yearDate = yearDate.subtract(1, 'year').startOf('year');
  }
  vm.year = vm.years[0];
  vm.month = vm.months[0];
  vm.shift = currentUser.shift ? vm.shifts.find((s) => s.value === currentUser.shift) || vm.shifts[0] : vm.shifts[0];
  
  $scope.$on('update-language', function (event, profileObj) {
    vm.lang = profileObj.key;
  });

  $scope.$on('update-units', function (event, profileObj) {
    vm.units = profileObj.key;
  });

  vm.downloadPDF = async function () {
    const doc = new jsPDF({
      orientation: 'landscape',
      format: 'a4'
    });
    doc.setFontSize(10);
    const dateFormat = vm.lang === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm';
    doc.text(`${vm.translate('PRINTED_DATE')}: ${vm.convertTime(dayjs(), dateFormat)}`, 15, 7);
    doc.text(`${organization.name}`, 80, 7);
    doc.setFont('Helvetica');
    doc.setFontSize(25);
    // const imgDataPath = `../images/${organization.graphics.logo_svg}`;
    // const imgData = await OrganizationService.fetchFileToString(imgDataPath);
    // console.log(imgData)
    // if (organization.graphics.logo_svg.endsWith('.svg')) {
    //   const data = await OrganizationService.base64SvgToBase64Png($window.btoa(imgData));
    //   console.log(data)
    //   doc.addImage(data, 'png', 10, 10, 40, 23)
    //   // await doc.addSvgAsImage(imgData, 10, 10, 400, 230);
    // } else if (organization.graphics.logo_svg.endsWith('.png')) {
    //   doc.addImage(imgData, 'png', 10, 10, 40, 23);
    // } else {
    //   throw new Error('Unsupported image format');
    // }

    let row = 45;
    let page = 1;
    if (vm.diveType === 'water') {
      // eslint-disable-next-line max-len
      doc.text(`${vm.translate('DIVES')}: ${vm.month.value === 'MONTH_SHORT' ? '' : vm.month.value + '/'}${vm.year.value === 'ALL' ? vm.translate('ALL').toLowerCase() : vm.year.value}`, 15, 25);
    } else if (vm.diveType === 'rescue') {
      // eslint-disable-next-line max-len
      doc.text(`${vm.translate('RESCUE_DIVES')}: ${vm.month.value === 'MONTH_SHORT' ? '' : vm.month.value + '/'}${vm.year.value === 'ALL' ? vm.translate('ALL').toLowerCase() : vm.year.value}`, 15, 25);
    } else {
      // eslint-disable-next-line max-len
      doc.text(`${vm.translate('EXPOSURE')}: ${vm.month.value === 'MONTH_SHORT' ? '' : vm.month.value + '/'}${vm.year.value === 'ALL' ? vm.translate('ALL').toLowerCase() : vm.year.value}`, 15, 25);
    }
    doc.setFontSize(14);
    if (vm.diveType === 'water') {
      doc.text(`${vm.translate('NAME')}`, 15, row);
      doc.text(`${vm.translate('SHIFT')}`, 70, row);
      doc.text(`${vm.translate('VACANCY_SHORT')}`, 90, row);
      doc.text(`${vm.translate('OVER_10_M_SHORT')}`, 110, row);
      doc.text(`${vm.translate('UNDER_10_M_SHORT')}`, 130, row);
      doc.text(`${vm.translate('WINTER')}`, 150, row);
      doc.text(`${vm.translate('POOL')}`, 170, row);
      doc.text(`${vm.translate('TRAINING')}`, 190, row);
      doc.text(`${vm.translate('ALARM')}`, 210, row);
      doc.text(`${vm.translate('TRAINING_DEPTH_SHORT')}`, 230, row);
      doc.text(`${vm.translate('ALL')}`, 260, row);
    } else if (vm.diveType === 'rescue') {
      doc.text(`${vm.translate('NAME')}`, 15, row);
      doc.text(`${vm.translate('SHIFT')}`, 70, row);
      doc.text(`${vm.translate('VACANCY_SHORT')}`, 90, row);
      doc.text(`${vm.translate('ALARM')}`, 110, row);
      doc.text(`${vm.translate('TRAINING')}`, 140, row);
      doc.text(`${vm.translate('TOTAL_DIVES_SHORT')}`, 170, row);
      doc.text(`${vm.translate('HAS_EXPOSURE')}`, 210, row);
      doc.text(`${vm.translate('LONG_DIVES')}`, 250, row);

    } else {
      let addRow = false;
      doc.text(`${vm.translate('NAME')}`, 15, row);
      doc.text(`${vm.translate('SHIFT')}`, 70, row);
      doc.text(`${vm.translate('VACANCY_SHORT')}`, 90, row);
      doc.text(`${vm.translate('TOTAL')}`, 110, row);
      if ((vm.translate('TOTAL_TIME') + ' (min)').length > 10) {
        addRow = true;
        doc.text(`${(vm.translate('TOTAL_TIME') + ' (min)')}`.substring(0, 10), 140, row);
        doc.text(`${(vm.translate('TOTAL_TIME') + ' (min)')}`.substring(10, 20), 140, row + 10);
      } else {
        doc.text(`${vm.translate('TOTAL_TIME')} (min)`, 140, row);
      }
      doc.text(`${vm.translate('HAS_BIO_MONITORING')}`, 170, row);
      doc.text(`${vm.translate('HAS_DIVE_TIME_SHORT')}`, 210, row);
      if (vm.translate('DIVE_TIME_EXPOSURE_LIMIT_REACHED').length > 18) {
        addRow = true;
        doc.text(`${vm.translate('DIVE_TIME_EXPOSURE_LIMIT_REACHED').substr(0, 17)}`, 250, row);
        doc.text(`${vm.translate('DIVE_TIME_EXPOSURE_LIMIT_REACHED').substr(17, 20)}`, 250, row + 10);
      } else {
        doc.text(`${vm.translate('DIVE_TIME_EXPOSURE_LIMIT_REACHED')}`, 250, row);
      }
      if (addRow) {
        row += 10;
      }
    }
    row += 10;
    const type  = vm.diveType === 'water' ? 'divers' : vm.diveType === 'rescue' ? 'rescuers' : 'exposures';
    vm[type].filter(vm.shiftFilter).forEach((d) => {
      if (vm.diveType === 'water') {
        doc.text(`${d.name}`, 15, row);
        doc.text(`${d.shift || ''}`, 70, row);
        doc.text(`${d.vacancy || ''}`, 90, row);
        doc.text(`${d.over_ten}`, 110, row);
        doc.text(`${d.under_ten}`, 130, row);
        doc.text(`${d.winter}`, 150, row);
        doc.text(`${d.pool}`, 170, row);
        doc.text(`${d.total_dives - d.alarm}`, 190, row);
        doc.text(`${d.alarm}`, 210, row);
        doc.text(`${d.deep}`, 230, row);
        doc.text(`${d.total_dives}`, 260, row);
      } else if (vm.diveType === 'rescue') {
        doc.text(`${d.name}`, 15, row);
        doc.text(`${d.shift || ''}`, 70, row);
        doc.text(`${d.vacancy || ''}`, 90, row);
        doc.text(`${d.alarm}`, 110, row);
        doc.text(`${d.training}`, 140, row);
        doc.text(`${d.total_dives}`, 170, row);
        doc.text(`${d.has_exposure}`, 210, row);
        doc.text(`${d.long}`, 250, row);
      } else {
        doc.text(`${d.name}`, 15, row);
        doc.text(`${d.shift || ''}`, 70, row);
        doc.text(`${d.vacancy || ''}`, 90, row);
        doc.text(`${d.total_exposures}`, 110, row);
        doc.text(`${d.total_exposure_time}`, 140, row);
        doc.text(`${d.dive_time_exposure_limit_reached}`, 170, row);
        doc.text(`${d.has_bio_monitoring}`, 210, row);
        doc.text(`${d.has_dive_time}`, 250, row);
      }
      row += 10;
      if (row > 265) {
        doc.addPage();
        page += 1;
        doc.setPage(page);
        row = 10;
      }
    });
    doc.save(`${vm.translate(vm.diveType === 'exposure' ? 'EXPOSURE_REPORT' : 'DIVE_REPORT')}-${vm.year.value === 'ALL' ? 
      vm.translate('ALL') : vm.year.value}${vm.month.value === 'MONTH_SHORT' ? 
      '' : '-' + vm.month.value}.pdf`);
  };

  vm.downloadCsv = function () {
    let csv = [];
    if (vm.diveType === 'water') {
      csv.push([vm.translate('NAME'), vm.translate('VACANCY'), vm.translate('SHIFT'), ...vm.diveParameters.map((p) => vm.translate(p.name))]);
      vm.divers.filter(vm.shiftFilter).forEach((d) => {
        csv.push([
          d.name,
          d.vacancy,
          d.shift,
          ...vm.diveParameters.map((p) => p.converted(d, p.value))
        ]);
      });
    } else if (vm.diveType === 'rescue') {
      csv.push([vm.translate('NAME'), vm.translate('VACANCY'), vm.translate('SHIFT'), ...vm.rescueDiveParameters.map((p) => vm.translate(p.name))]);
      vm.rescuers.filter(vm.shiftFilter).forEach((d) => {
        csv.push([
          d.name,
          d.vacancy,
          d.shift,
          ...vm.rescueDiveParameters.map((p) => p.converted(d, p.value))
        ]);
      });
    } else if (vm.diveType === 'exposure') {
      csv.push([vm.translate('NAME'), vm.translate('VACANCY'), vm.translate('SHIFT'),  ...vm.exposureParameters.map((p) => vm.translate(p.name))]);
      vm.exposures.filter(vm.shiftFilter).forEach((d) => {
        csv.push([
          d.name,
          d.vacancy,
          d.shift,
          ...vm.exposureParameters.map((p) => p.converted(d, p.value))
        ]);
      });
    } else {
      throw new Error('Unsupported dive type');
    }
    const csvContent = 'data:text/csv;charset=utf-8,' + csv.map(e => e.join(';')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${vm.translate(vm.diveType === 'exposure' ? 'EXPOSURE_REPORT' : 'DIVE_REPORT')}-${vm.year.value === 'ALL' ? 
      vm.translate('ALL') : vm.year.value}${vm.month.value === 'MONTH_SHORT' ? 
      '' : '-' + vm.month.value}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  vm.shiftFilter = function (item) {
    if (vm.shift?.value !== 'ALL_WORK_SHIFTS') {
      return item.shift === vm.shift?.value || item.shift == '';
    } else {
      return true;
    }
  };

  vm.getReport = () => {
    if (vm.year.value === 'ALL') {
      vm.month = vm.months[0];
    }
    if (vm.month.value === 'MONTH_SHORT') {
      vm.from = vm.year.value !== 'ALL' ? dayjs(vm.year.value, 'YYYY').startOf('year') : dayjs('1980-01-01').startOf('year');
      vm.to = vm.year.value !== 'ALL' ? dayjs(vm.year.value, 'YYYY').endOf('year') : dayjs();
      vm.fromStr = vm.from.format('DD.MM.YYYY hh:mm');
      vm.toStr = vm.to.format('DD.MM.YYYY hh:mm');
    } else {
      vm.from = dayjs(`${vm.year.value}-${vm.month.value}-1`).startOf('month');
      vm.to = dayjs(`${vm.year.value}-${vm.month.value}-1`).endOf('month');
      vm.fromStr = vm.from.format('DD.MM.YYYY hh:mm');
      vm.toStr = vm.to.format('DD.MM.YYYY hh:mm');
    }

    UserService.getOrganizationReport(organizationId, vm.from.valueOf(), vm.to.valueOf(), vm.diveType).then((rep) => {
      if (vm.diveType === 'water') {
        vm.divers = rep.filter(({ _id }) => diversMap[_id]?.diver)
          .map((orgReport) => {
            const { firstname, lastname, vacancy, shift } = diversMap[orgReport._id];
            return {
              _id: orgReport._id,
              name: `${firstname} ${lastname}`,
              vacancy,
              shift,
              ...orgReport
            };
          });
      } else if (vm.diveType === 'rescue') {
        vm.rescuers = rep.map(orgReport => {
          const { firstname, lastname, vacancy, shift } = diversMap[orgReport._id];
          return {
            ...orgReport,
            _id: orgReport._id,
            name: `${firstname} ${lastname}`,
            vacancy,
            shift
          };
        }) 
      } else if (vm.diveType === 'exposure') {
        vm.exposures = rep.map((orgReport) => {
          if (!orgReport._id || orgReport._id === 'undefined') {
            console.log('Filtering out exposure report with undefined id', orgReport);
            return null;
          }
          const { firstname, lastname, vacancy, shift } = diversMap[orgReport._id];
          return {
            _id: orgReport._id,
            name: `${firstname} ${lastname}`,
            vacancy,
            shift,
            ...orgReport
          }
        }).filter((v) => v !== null);
      }
    }, (err) => {
      console.log(err);
    });

    // UserService.getExposureReport(organizationId, vm.from.valueOf(), vm.to.valueOf()).then((dives) => {
    //   console.log(dives)
    //   vm.divers = dives.map(({ _id, total_exposure, over_limit_times }) => {
    //     const { firstname, lastname, vacancy, shift } = diversMap[_id];
    //     return {
    //       _id,
    //       name: `${firstname} ${lastname}`,
    //       vacancy,
    //       shift,
    //       over_limit_times,
    //       total_exposure
    //     }
    //   });
    // }, (err) => {
    //   console.log(err);
    // });
  };
  vm.getReport();
};

// eslint-disable-next-line max-len
ReportsOrganizationController.$inject = ['$rootScope', '$state', '$scope', '$stateParams', '$translate', 'UserService', 'currentUser', 'user', 'organization', 'diversMap', 'OrganizationService'];

// eslint-disable-next-line max-len
export default angular.module('ReportsOrganizationController', [OrganizationService, UserService, OrganizationService]).controller('ReportsOrganizationController', ReportsOrganizationController).name;
