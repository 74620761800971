import * as angular from 'angular';
import AuthenticationService from './AuthenticationService';
import options from './options';

const UserService = function ($q, $http, $window, options, AuthenticationService) {
  return {
    currentUser: null,

    get(userId) {
      return $http.get(`${options.api.base_url}/users/${userId}/`);
    },
    getTargetUser(userId, organizationId) {
      if (!userId || !organizationId) return Promise.reject(new Error('No userId or organizationId defined for getTargetUser'));
      const d = $q.defer();
      $http({
        url: `${options.api.base_url}/users/context/${organizationId}/${userId}`,
        method: 'GET',
        cache: false,
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },
    getContext(userId, organizationId) {
      if (!organizationId) return Promise.reject(new Error('No organizationId defined for getContext'));
      const d = $q.defer();
      $http({
        url: `${options.api.base_url}/users/${userId}/context/${organizationId}`,
        method: 'GET',
        cache: false,
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },
    getOrganizationReport(organizationId, from, to, diveType) {
      if (!organizationId) return Promise.reject(new Error('No organizationId defined for getOrganizationReport'));
      const d = $q.defer();
      let url = '';
      if (diveType === 'water') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/dives`;
      } else if (diveType === 'rescue') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/rescue-dives`;
      } else if (diveType === 'exposure') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/exposures`;
      }
      $http({
        url,
        method: 'GET',
        cache: false,
        params: {
          from,
          to,
        }
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },
    getUserReport(organizationId, diverId, from, to, diveType) {
      if (!organizationId) return Promise.reject(new Error('No organizationId defined for getUserReport'));
      const d = $q.defer();
      let url = '';
      if (diveType === 'water') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/dives/${diverId}`;
      } else if (diveType === 'rescue') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/rescue-dives/${diverId}`;
      } else if (diveType === 'exposure') {
        url = `${options.api.base_url}/organizations/${organizationId}/reports/exposures/${diverId}`;
      } else { 
        return Promise.reject(new Error('No diveType defined for getUserReport'));
      }
      $http({
        url,
        method: 'GET',
        cache: false,
        params: {
          from,
          to,
        }
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },
    list(organizationId) {
      if (!organizationId) return Promise.reject(new Error('No organizationId defined for list users'));
      return $http.get(`${options.api.base_url}/organizations/${organizationId}/users`);
    },
    listMap(organizationId) {
      if (!organizationId) return Promise.reject(new Error('No organizationId defined for list users'));
      return $http.get(`${options.api.base_url}/organizations/${organizationId}/usersMap`);
    },

    login(username, password) {
      const d = $q.defer();
      $http({
        url: `${options.api.base_url}/users/login`,
        method: 'POST',
        data: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });
      return d.promise;
    },

    logout() {
      const d = $q.defer();
      return $http.get(`${options.api.base_url}/users/logout`)
        .then((res) => {
          console.log('logged out');
          AuthenticationService.isAuthenticated = false;
          delete $window.sessionStorage.token;
          return d.resolve(true);
        }, (err) => {
          d.reject(err.data);
        });
    },

    register(user) {
      if (!user) return Promise.reject(new Error('No user data defined for register'));
      const d = $q.defer();
      $http({
        url: `${options.api.base_url}/users/register`,
        method: 'POST',
        data: JSON.stringify(user),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },

    confirmNewUsername(token) {
      if (!token) return Promise.reject(new Error('No token defined for confirmNewUsername'));
      const d = $q.defer();
      console.log('sending confirmation');
      $http({
        url: `${options.api.base_url}/users/confirm/${token}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((results) => {
          console.log(results);
          d.resolve(results.data);
        }, (err) => {
          console.log(err);
          d.reject(err.data);
        });

      return d.promise;
    },

    update(data, organizationId) {
      if (!data) return Promise.reject(new Error('No data defined for update user'));
      console.log(data);
      const d = $q.defer();
      const userId = data._id;
      $http({
        url: `${options.api.base_url}/users/${userId}/organizations/${organizationId}`,
        method: 'PUT',
        data: JSON.stringify({ user: data }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          d.reject(err.data);
        });

      return d.promise;
    },

    deleteUser(id) {
      if (!id) return Promise.reject(new Error('No id defined for delete'));
      return $http.delete(`${options.api.base_url}/users/${id}`);
    },

    deleteAccount(userId, organizationId) {
      const d = $q.defer();
      $http({
        url: `${options.api.base_url}/users/${userId}/organizations/${organizationId}`,
        method: 'DELETE',
      })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          d.reject(err.data);
        });
  
      return d.promise;
    },

    changePassword(username, oldPassword, newPassword) {
      const d = $q.defer();
      $http.post(`${options.api.base_url}/users/password`, { username, oldPassword, newPassword })
        .then((results) => {
          d.resolve(results.data);
        }, (err) => {
          d.reject(err.data);
        });
      return d.promise;
    },

    resetPassword(token, newPassword) {
      return $http.post(`${options.api.base_url}/users/reset_password/${token}`, { password: newPassword });
    },

    sendUsername(username) {
      return $http.post(`${options.api.base_url}/users/reset_password`, { username });
    },

  };
};

UserService.$inject = ['$q', '$http', '$window', 'options', 'AuthenticationService']
export default angular.module('UserService', [options, AuthenticationService]).factory('UserService', UserService).name;
