import * as angular from 'angular';
import UserService from '../services/UserService';

const ContactController = function ($stateParams, organization, currentUser, UserService) {

  const vm = this;
  vm.divers = [];
  vm.organizationId = organization._id;
  vm.isAdmin = currentUser && (['admin', 'superadmin'].includes(currentUser.role));
  vm.isSuperAdmin = currentUser && currentUser.role === 'superadmin';

  UserService.list($stateParams.organizationId).then((divers) => {
    vm.divers = divers.data.filter((d) => (d.status && d.status === 'enabled')).map((diver, index) => {
      const name = `${diver.lastname || ''} ${diver.firstname || ''}`;
      return {
        ...diver,
        name
      }
    });
  });
}
ContactController.$inject = ['$stateParams', 'organization', 'currentUser', 'UserService'];

export default angular.module('ContactController', [UserService]).controller('ContactController', ContactController).name;
